import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { base, appDatabasePrimaryFunctions } from '../../base';
import '../../styles/css/AdminMain.css';
import DatePicker from '../utils/DatePicker';
import {cleanCSVText, convertTimeStampToHumanReadable, findFirstAndLastName} from '../utils/HelpfulFunction';
import swal from 'sweetalert2';

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
          ticketList: [],
          rewardsAdded: [],
          userAnswers:[],
          currentGameRewards: [],
          top_prize_select: 'top',
          currentGame: null,
          modal: false,
          userGameHistory: [],
          codes: false,
          users: [],
          codesArray: '',
          loading: true,
          wizardLocation: 'first',
          showStats: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    getCurrentTime(datePassedIn){
        if(!datePassedIn){
            datePassedIn = new Date();
        }
        return `${datePassedIn.getFullYear()}-${`${datePassedIn.getMonth() + 1}`.padStart(2, 0)}-${`${datePassedIn.getDate()}`.padStart(2, 0)}T${`${datePassedIn.getHours()}`.padStart(2, 0)}:${`${datePassedIn.getMinutes()}`.padStart(2, 0)}`;
    }

    componentDidMount() {
          this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
          });
        this.currentGameRef = base.syncState(`currentGame`, {
            context: this,
            state: 'currentGame',
            then(err){
                if(!err){
                    this.setGameToWatch(this.state.currentGame.id);
                }
                this.setState({loading:false})
            }
        });
        const vm = this;
        this.userAnswersRef = appDatabasePrimaryFunctions.ref("userAnswers").on('value', function(snapshot){
            vm.setState({
                userAnswerCount: snapshot.numChildren()
            })
        })
    }

    async downloadUsers(){
        this.setState({loading: true})
        let users = await appDatabasePrimaryFunctions.ref("users").once('value').then(function(snapshot){
            return snapshot.val()
        });
        let answerList = await this.sortPlayersForAdmin();
        // const currentUsersState = this.getAndSortUsers(players, userAnswers) || [];
        let csv = 'Email,First Name,Last Name,Phone Number,Zip Code,Birthday,Opt-In,High Score,Timestamp\n';
        answerList[0].forEach(function(row) {
            let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
            csv += (users[row.uid].email || row.uid || "");
            csv+= ",";
            csv+= (cleanCSVText(firstName) || "");
            csv+= ",";
            csv+= (cleanCSVText(secondName) || "");
            csv+= ",";
            csv+= (users[row.uid].phoneNumber || "");
            csv+= ",";
            csv+= (cleanCSVText(users[row.uid].zipCode) || "");
            csv+= ",";
            csv+= (users[row.uid].birthday || "");
            csv+= ",";
            csv+= (users[row.uid].optIn || "");
            csv+= ",";
            csv+= (row.highScore || 0);
            csv+= ",";
            csv+= row.timeStamp ? convertTimeStampToHumanReadable(row.timeStamp) : "";
            csv += "\n";
        }, this);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv";
        hiddenElement.download = "current_users_" + date_got;
        hiddenElement.click();
        this.setState({loading: false})
    }

    setGameToWatch(id){
        if(this.userGameHistoryRef){
            appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
        }
        if(id){
            const vm = this;
            this.userGameHistoryRef = appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(id).equalTo(id).on('value', function(snapshot){
                vm.setState({
                    userGameHistory: snapshot.numChildren()
                })
            })
        }
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
      base.removeBinding(this.currentGameRef);
        base.removeBinding(this.userGameHistoryRef);
        base.removeBinding(this.userAnswersRef);
    }

    async createGame(){
      const createGameObject = {};
      const gameId = appDatabasePrimaryFunctions.ref().push().key;
        let startTime = this.state.startTime;
        let endTime = this.state.endTime;
        let gameName = this.state.gameName.trim();
        if(!startTime || !endTime){
            swal({
                title: "Hold On!",
                text: 'The game needs a start time and end time!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        startTime = new Date(startTime).getTime()/1000;
        endTime = new Date(endTime).getTime()/1000;
        if(startTime >= endTime){
            swal({
                title: "Hold On!",
                text: 'The game start time cannot be equal to or greater than the game end time!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        if(startTime <= Date.now()/1000){
            swal({
                title: "Hold On!",
                text: 'The game start time cannot be before now!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        if(!gameName){
            swal({
                title: "Hold On!",
                text: 'The game needs a name!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        this.setState({loading:true})
        let currentGame = this.state.currentGame || {};
        currentGame['users'] = this.state.userGameHistory || 0;
        currentGame['answers'] = this.state.userAnswerCount || 0;
        const emailsSentCount = await appDatabasePrimaryFunctions.ref('emailsSent').once('value').then( function (snapshot) {return snapshot})
        currentGame['prizesWon'] = emailsSentCount.numChildren() || 0;
        const vm = this;
        createGameObject.active = false;
        createGameObject.ended =  false;
        createGameObject.gameName = gameName;
        createGameObject.scheduleInfo = {};
        createGameObject.scheduleInfo['status'] = 'scheduled';
        createGameObject.scheduleInfo['performAt'] = startTime;
        createGameObject.scheduleInfo['endAt'] = endTime;
        createGameObject.id = gameId;
        createGameObject.timeStamp = Date.now();
        if(currentGame.id){
            const sortPlayersForAdmin = await this.sortPlayersForAdmin();
            base.post(`userAnswersHistory/${currentGame.id}`, {
                data: sortPlayersForAdmin[0],
                then(data){
                    console.log("User Answers Saved!")
                }
            })
            base.push('gamesList', {
                data: currentGame,
                then(err) {
                    if (!err) {
                        vm.setState({
                            currentGame: createGameObject,
                            currentUserAnswersState: [],
                            modal: false,
                            loading: false
                        }, () => {
                            vm.setGameToWatch(gameId);
                            appDatabasePrimaryFunctions.ref("userAnswers").set(null);
                            appDatabasePrimaryFunctions.ref("emailsSent").set(null);
                            appDatabasePrimaryFunctions.ref("prizeWon").set(null);
                        })
                    } else {
                        alert("Error Occurred: " + err);
                        vm.setState({
                            loading: false
                        })
                    }
                }
            })
        } else {
            vm.setState({
                currentGame: createGameObject,
                currentUserAnswersState: [],
                modal: false,
                loading: false
            }, () => {
                vm.setGameToWatch(gameId);
                appDatabasePrimaryFunctions.ref("userAnswers").set(null);
                appDatabasePrimaryFunctions.ref("emailsSent").set(null);
                appDatabasePrimaryFunctions.ref("prizeWon").set(null);
            })
        }
    }

    stopGame(){
      const currentGame = this.state.currentGame;
      currentGame.active = false;
      this.setState({
        currentGame: currentGame
      })
      swal({
        title: 'Game Stopped',
        type: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: 10000
      })
    }

    startGame() {
        const currentGame = this.state.currentGame;
        currentGame.timeStamp = Date.now();
        currentGame.active = true;
        this.setState({
          currentGame: currentGame
        })
        swal({
            title: 'Game Started',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
          })
    }

    handleChange (evt) {
      var target = evt.target;
      var value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first"
      });
    }

    getSnapshotFromEndpoint(endpoint, numberOfResults=null, searchData=null, orderBy=null) {
        let baseRef = appDatabasePrimaryFunctions.ref(endpoint);
        if(orderBy){
            baseRef = baseRef.orderByChild(orderBy)
        }
        if(numberOfResults){
            baseRef = baseRef.limitToFirst(numberOfResults);
        }
        return baseRef.once('value').then(function(snapshot){
            return snapshot;
        })
    }

    async sortPlayersForAdmin(numberOfResults=0, searchData=null){
        return await Promise.all([this.getSnapshotFromEndpoint('currentGame'), this.getSnapshotFromEndpoint('userAnswers', numberOfResults, searchData, 'timeStamp'), this.getSnapshotFromEndpoint('emailsSent'), this.getSnapshotFromEndpoint('users')]).then(async function(snapshots){
            let userAnswers = {};
            if(snapshots[1].exists() && snapshots[1].val()){
                userAnswers = snapshots[1].val();
            }
            let users = {};
            if(snapshots[3].exists() && snapshots[3].val()){
                users = snapshots[3].val();
            }
            const usersAnswersAttempted = [];
            for(let userAnswerIndex in userAnswers){
                const user = {};
                if(!users[userAnswerIndex]){
                    continue
                }
                const userEmail = users[userAnswerIndex].email;
                user['timeStamp'] = userAnswers[userAnswerIndex].timeStamp;
                user['email'] = userEmail;
                user['uid'] = userAnswerIndex;
                user['highScore'] = userAnswers[userAnswerIndex].highScore;
                if(searchData){
                    const cleanSearchData = searchData.toLowerCase();
                    const lowerCaseEmail = userEmail.toLowerCase();
                    if(lowerCaseEmail.indexOf(cleanSearchData) !== -1){
                        usersAnswersAttempted.push(user)
                    }
                } else {
                    usersAnswersAttempted.push(user)
                }
            }
            const cmp = (a, b) => (a > b) - (a < b);
            usersAnswersAttempted.sort(function(a, b) {
                return cmp(b.highScore,a.highScore) || cmp(a.timeStamp,b.timeStamp)
            });
            return [usersAnswersAttempted, false]
        })
    }

    resetGame(){
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will erase all information gathered during this game! Only do this if no one has played yet.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES I WANT TO RESET THE GAME',
        }).then(async (result)=>{
          if(result.value){
              let currentGameState = await appDatabasePrimaryFunctions.ref('currentGame').once('value').then(function(snapshot){
                  return snapshot.val()
              });
              currentGameState.active = false;
              let history = await appDatabasePrimaryFunctions.ref(`userGameHistory`).orderByChild(currentGameState.id).equalTo(currentGameState.id).once('value').then(function(snapshot){
                  return snapshot.val()
              });
              for(let historyRef in history){
                  let refInstance = history[historyRef];
                  if(refInstance && refInstance[currentGameState.id]){
                      appDatabasePrimaryFunctions.ref(`userGameHistory/${historyRef}/${currentGameState.id}`).set(null);
                  }
              }
              if(currentGameState.id){
                  appDatabasePrimaryFunctions.ref(`currentGameFormFilled/${currentGameState.id}`).set(null);
              }
              appDatabasePrimaryFunctions.ref("emailsSent").set(null);
              appDatabasePrimaryFunctions.ref("userAnswers").set(null);
              appDatabasePrimaryFunctions.ref("prizeWon").set(null);
              this.setState({
                  currentGame: currentGameState,
                  currentUserAnswersState: []
              })
          }
        })
    }

    navButtonClicked(direction){
      var currentLocation = this.state.wizardLocation;
      if(direction === 'prev'){
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      } else if(direction === 'next'){
        this.setState({
          wizardLocation: 'third',
        })
        this.toggleWizardLocation('third', 'first', 'second')
      // } else
      // if(direction === 'prev' && currentLocation === 'second'){
      //   this.setState({
      //     wizardLocation: 'first'
      //   })
      //   this.toggleWizardLocation('first', 'second', 'third')
      // } else if(direction === 'prev' && currentLocation === 'third'){
      //   this.setState({
      //     wizardLocation: 'second'
      //   })
      //   this.toggleWizardLocation('second', 'first', 'third')
      // } else if(direction === 'next' && currentLocation === 'first'){
      //   this.setState({
      //     wizardLocation: 'second',
      //   })
      //   this.toggleWizardLocation('second', 'first', 'third')
      // } else if(direction === 'next' && currentLocation === 'second'){
      //   this.setState({
      //     wizardLocation: 'third',
      //   })
      //   this.toggleWizardLocation('third', 'first', 'second')
      } else {
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2){
      this.setState({
        wizardLocation: tabClicked
      })
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
    }

    async switchStatsPrizes(){
      if(this.state.showStats){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showStats').classList.add('active');
        this.setState({
          showStats:false,
        })
      }else{
        this.setState({
          loading: true
        })
        const currentUserAnswersState = await this.sortPlayersForAdmin();
        document.getElementById('showPrizes').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
          this.setState({
              showStats:true,
              loading: false,
              currentUserAnswersState: currentUserAnswersState[0]
          })
      }
    }

    render() {
        let selectedGame = this.state.currentGame || {};
        let currentUserAnswersState = this.state.currentUserAnswersState || [];
        let userGameHistory = this.state.userGameHistory || 0;
        let userAnswerCount = this.state.userAnswerCount || 0;
        let gameName = null;
        const gameActive = selectedGame.active;
        let startTimeFirstPart = null;
        let startTimeSecondPart = null;
        let endTimeSecondPart = null;
        let endTimeFirstPart = null;
        if(selectedGame && selectedGame.gameName){
            if(selectedGame.scheduleInfo){
                startTimeFirstPart = new Date(selectedGame.scheduleInfo.performAt*1000).toLocaleDateString();
                startTimeSecondPart = new Date(selectedGame.scheduleInfo.performAt*1000).toLocaleTimeString();
                endTimeFirstPart = new Date(selectedGame.scheduleInfo.endAt*1000).toLocaleDateString();
                endTimeSecondPart = new Date(selectedGame.scheduleInfo.endAt*1000).toLocaleTimeString();
            }
            gameName = selectedGame.gameName;
        } else {
            selectedGame = null;
        }
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-2" style={{display: selectedGame && selectedGame.ended ? '' : 'none'}}>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px'}}>
                    <p style={{lineHeight:'50px'}}>Game Ended</p>
                  </div>
                </div>
                <div className="col-md-2" style={{display: !selectedGame ? 'none' : ''}}>
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Is Active</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? 'none' : '', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game is Inactive</p>
                  </div>
                </div>
                <div className="col-md-2" style={{display: !selectedGame ? 'none' : ''}}>
                  <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: gameActive ? 'none' : '', float:'left', height:'52px'}}>Start Game</button>
                  <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: gameActive ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                </div>
                {/*<div className="col-md-2">*/}
                {/*  <button onClick={() => this.endGame()} className="btn btn-primary btn-lg end-game-button" style={{ height:'52px' }}>Start Scoreboard</button>*/}
                {/*</div>*/}
                <div className="col-md-6">
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetGame()} style={{display: !selectedGame ? 'none' : '', marginTop:'7px', float:'right'}}>Reset Game</a>
                  <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                </div>
              </div>
            </div>
              <div style={{display: !selectedGame ? 'none': 'block'}}>
                  <div className="container">
                      <div className="row">
                          {startTimeFirstPart &&
                          <div className="col-md-4"
                               style={{color: "black", border: "solid 1px #d3d3d3", margin: 10, borderRadius: 5}}>
                              <div className="row">
                                  <div className="col-md-6">
                                      <u style={{color: "#353839"}}>Starts</u>
                                      <br/>
                                      {selectedGame && selectedGame.scheduleInfo && selectedGame.scheduleInfo.performAt  ?
                                          <span style={{fontSize: 18}}>{startTimeFirstPart}<br/>{startTimeSecondPart}</span>
                                          :
                                          <span style={{fontSize: 18}}>No Scheduled Start</span>
                                      }
                                      {/*<span style={{fontSize: 18}}>{startTimeFirstPart}<br/>{startTimeSecondPart}</span>*/}
                                  </div>
                                  <div className="col-md-6">
                                      <u style={{color: "#353839"}}>Ends</u>
                                      <br/>
                                      {selectedGame && selectedGame.scheduleInfo && selectedGame.scheduleInfo.endAt  ?
                                          <span style={{fontSize: 18}}>{endTimeFirstPart}<br/>{endTimeSecondPart}</span>
                                          :
                                          <span style={{fontSize: 18}}>No Scheduled End</span>
                                      }
                                  </div>
                              </div>
                          </div>
                          }
                          <div className="col-md-4" style={{color: "black", border: "solid 1px #d3d3d3", marginTop:10, marginBottom:10, marginLeft:10, borderRadius: 5}}>
                              <div className="row">
                                  <div className="col-md-6" style={{textAlign:'center'}}>
                                      <span style={{fontSize:12}}>Current Game Players</span>
                                      <footer className="value-text">{userGameHistory}</footer>
                                  </div>
                                  <div className="col-md-6" style={{textAlign:'center'}}>
                                      <span style={{fontSize:12}}>Players Score > 0</span>
                                      <footer className="value-text">{userAnswerCount}</footer>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            <div className="">
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Game Details</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes()}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Game Status</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                    <div className="row row-cols-auto" style={{marginLeft: 10, marginRight: 10}}>
                        <div className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}>
                            <span className="fa fa-arrow-circle-down"/> Download Participants
                        </div>
                    </div>
                    <div style={{height: '10px', width: '100%'}}/>
                <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>Email</th>
                        <th>High Score</th>
                        <th>Time Completed</th>
                      </tr>
                      {
                          currentUserAnswersState.map(function(item,i){
                              return <tr key={i}>
                                  <td style={{fontFamily:'Open Sans'}}>{item.email}</td>
                                  <td style={{fontFamily:'Open Sans' }}>{item.highScore}</td>
                                  <td style={{fontFamily:'Open Sans' }}>{item.timeStamp ? convertTimeStampToHumanReadable(item.timeStamp) : ""}</td>
                              </tr>
                          }, this)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: !this.state.showStats ? 'block' : 'none'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" style={{textAlign:"center"}}>
                                <h4 style={{color:'black'}}>Game Name: {gameName}</h4>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> The Basics</span>
                            </a>
                        </li>
                        <li style={{display:"none"}} className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-hourglass-half"/>
                                <span className="d-none d-sm-inline"> Timing</span>
                            </a>
                        </li>
                        {/*<li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third')}>*/}
                        {/*    <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">*/}
                        {/*        <span className="fa fa-trophy"/>*/}
                        {/*        <span className="d-none d-sm-inline"> Add Prizes</span>*/}
                        {/*    </a>*/}
                        {/*</li>*/}
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Finish</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                            <div className="col-md-9">
                                                <input id="gameName" name="gameName" type="text" className="form-control" value={this.state.gameName} onChange={this.handleChange} placeholder="12/11 vs MonStars" />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="startTime"> Set Start Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.startTime} onChange={date => this.setState({startTime: date})}/>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="endTime"> Set Stop Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.endTime} onChange={date => this.setState({endTime: date})}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                       <div className="tab-pane" id="second">
                           <div className="form-horizontal">
                               <div className="row">
                                   <div className="col-12">
                                       <div className="form-group row mb-3">
                                           <label className="col-md-3 col-form-label" htmlFor="surname3"> Seconds Showing Question</label>
                                           <div className="col-md-9">
                                               <input id="secondsBetweenQuestions" name="secondsBetweenQuestions" min="0" type="number" className="form-control" value={this.state.secondsBetweenQuestions} onChange={this.handleChange} placeholder="5" />
                                           </div>
                                       </div>

                                       <div className="form-group row mb-3">
                                           <label className="col-md-3 col-form-label" htmlFor="surname3"> Seconds Showing Answer</label>
                                           <div className="col-md-9">
                                               <input id="secondsShowingAnswer" name="secondsShowingAnswer" min="0" type="number" className="form-control" value={this.state.secondsShowingAnswer} onChange={this.handleChange} placeholder="5" />
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>

                      {/*    <div className="tab-pane" id="second">
                            <div className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="name3"> Select Reward</label>
                                            <div className="col-md-9">
                                              <select className="form-control" name="ticket_select" id="ticket_select" value={this.state.ticket_select} onChange={this.handleChange}>
                                                <option></option>
                                                {
                                                  this.state.ticketList.map(function(item,index){
                                                    return(
                                                      <option value={item.key} key={index}>{item.rewardName}</option>
                                                    )
                                                  })
                                                }
                                              </select>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="surname3"> How Many Of This Reward To Give Out?</label>
                                            <div className="col-md-9">
                                                <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="5" />
                                            </div>
                                        </div>

                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="email3">Redeem Codes?</label>
                                            <div className="col-md-9">
                                                <input value={this.state.codes} name="codes" type="checkbox" checked={this.state.codes} onChange={this.handleChange} />
                                            </div>
                                        </div>

                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="email3">Type of prize</label>
                                            <div className="col-md-9">
                                              <select className="form-control" name="top_prize_select" id="top_prize_select" value={this.state.top_prize_select} onChange={this.handleChange}>
                                                <option value="top">Top Prize</option>
                                                <option value="slow">Too Slow Prize</option>
                                              </select>
                                            </div>
                                        </div>

                                        <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none' }}>
                                          <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Codes (code1,code2,code3)</label>
                                          <div className="col-md-9">
                                            <textarea value={this.state.codesArray} className="form-control" name="codesArray" checked={this.state.codesArray} onChange={this.handleChange} placeholder="code1,code2,code3"/>
                                          </div>
                                        </div>

                                        <div className="form-group row mb-3" align="center">
                                          <div className="col-md-12">
                                            <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray()}>Add Reward</button>
                                          </div>
                                        </div>

                                        <div className="form-group mb-3">
                                            <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                              {
                                                rewardsAdded.map(function(item,index){
                                                  return(
                                                    <div key={index} className="form-group">
                                                      <li>) {item.amount} - {item.reward.rewardName} - {item.typePrize}<span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.removeFromToAddArray(index)}></span></li>
                                                    </div>
                                                  )
                                                })
                                              }
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>*/}

                        <div className="tab-pane fade" id="third">
                            <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <h2 className="mt-0">
                                                <i className="mdi mdi-check-all"/>
                                            </h2>
                                            <h3 className="mt-0">Finish</h3>

                                            <div className="row form-group">
                                              <div className="col-md-12">
                                                <p>You are all set to create a Jumper Game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                              </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a>
                            </li>
                            <li className="next list-inline-item float-end"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'third' ? 'none' : '' }}>Next</a></li>
                            <li className="next list-inline-item float-end"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>Create Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
