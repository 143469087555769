import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';

class SetUpTicketEmail extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
          helpText: '',
          subjectLine: '',
          emailBackgroundImage: '',
          loading:true,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentWillUnmount() {
      base.removeBinding(this.subjectLineRef);
      base.removeBinding(this.helpTextRef);
      base.removeBinding(this.emailBackgroundImageRef);
    }

    componentDidMount() {
        this.subjectLineRef = base.bindToState(`emailVariables/subjectLine`, {
            context: this,
            state: 'subjectLine',
        });
        this.helpTextRef = base.bindToState(`emailVariables/helpText`, {
            context: this,
            state: 'helpText',
        });
        this.emailBackgroundImageRef = base.bindToState(`emailVariables/emailBackgroundImage`, {
            context: this,
            state: 'emailBackgroundImage',
        });
      this.setState({
        loading: false
      })
    }

    handleChange(event){
      this.setState({[event.target.name]: event.target.value});
    }

    handleImageChange(event){
      var name_of_file = event.target.name;
      var target = this[name_of_file];
      var file_to_update =target.files[0];
      this.setState({loading:true})
      var vm = this;
      this.UploadImage.upload_file(file_to_update).then(res => {
        setTimeout(function () {
          vm.setState({loading:false})
          var imageUrl = res.data.imageUrl;
          if(imageUrl){
            vm.setState({
              [name_of_file]: imageUrl,
            }, () => {
                vm[name_of_file].value = ""
            });
          } else {
            swal({
                title: 'Image cannot be uploaded',
                text: 'There was some issue with the image upload, please reupload and try again',
                type: 'warning',
                confirmButtonText: 'Ok'
              })
          }
        }, 3000)
      })
      this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
      event.preventDefault();
      var helpText = this.state.helpText;
      var subjectLine = this.state.subjectLine;
      var teamIconImage = this.state.teamIconImage;
      var backgroundImage = this.state.emailBackgroundImage;
      if(!helpText || !subjectLine || !teamIconImage || !backgroundImage){
        swal({
            title: 'Missing fields',
            text: "Missing Required Fields!",
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      this.setState({loading:true})
      var updateRulesObject = {}
      updateRulesObject['helpText'] = helpText;
      updateRulesObject['subjectLine'] = subjectLine;
      updateRulesObject['teamIconImage'] = teamIconImage;
      updateRulesObject['emailBackgroundImage'] = backgroundImage;
      var vm = this;
      base.post("emailVariables/", {
        data: updateRulesObject,
        then(err){
          vm.setState({loading:false})
          if(!err){
            swal({
                title: 'Rules and Regs Updated!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
          } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
            console.log(err)
          }
        }
      })

      // var vm = this;
      // this.setState({loading:true})
      // this.Auth.fetch(domainRoot + 'services/app/Tenant/UpdateEmailTemplate', {
      //   method: 'POST',
      //   body: formData,
      // }).then( res => {
      //   this.setState({loading:false})
      //   if(res){
      //     if(res.apiStatus === "tenant_email_updated"){
      //       swal({
      //           title: "Email Edited",
      //           type: 'success',
      //           toast: true,
      //           position: 'top-end',
      //           showConfirmButton: true,
      //           timer: 10000
      //         })
      //       vm.setState({
      //         modal: false,
      //         subjectLine: res.entity.subjectLine,
      //         helpText: res.entity.emailHelpText,
      //         emailBackgroundImage: res.entity.emailBackgroundImage,
      //         teamIconImage: res.entity.teamIconImage,
      //       })
      //     }
      //   } else {
      //     swal({
      //         title: 'Uh oh!',
      //         text: 'There was some error! Check your connection and logout and log back in if the error persists',
      //         type: 'error',
      //         confirmButtonText: 'Ok'
      //       })
      //   }
      // }).catch(err => {
      //   console.log(err)
      //   swal({
      //       title: 'Uh oh!',
      //       text: 'There was some error! Check your connection and logout and log back in if the error persists',
      //       type: 'error',
      //       confirmButtonText: 'Ok'
      //     })
      // })
    }

    render() {
      let helpText = this.state.helpText;
      let subjectLine = this.state.subjectLine;
      if(typeof helpText === "object"){
        helpText = ""
      }
      if(typeof subjectLine === "object"){
        subjectLine = ""
      }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}></div>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              {/*
              <p style={{float:'right', color:'black', fontSize:'25px', textAlign:'center', marginRight: 20}}>Help 👇<br/><a href="#">Image sizes & specs PDF</a></p>
              */}
              <p className="admin-header-text" style={{marginBottom:0}}>Email Branding</p>
              <p className="admin-subheader-text">This is where you can edit the email sent to fans</p>
              <div className="container-out">
                <div className="admin-form-box">
                  <form onSubmit={this.handleSubmit} id="create-email-form">
                    <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:20}}><span className="fa fa-arrow-circle-o-up"></span> Update</button>
                    <div className="row col-md-12">
                      <div className="form-group">
                        <label htmlFor="subjectLine">Email Subject Line</label>
                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is the subject line that your fans will see when they receive their winning emails</p>
                        <input id="subjectLine" name="subjectLine" className="form-control" value={subjectLine} onChange={this.handleChange} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-3">
                        <label htmlFor="helpText">Help Text</label>
                        <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>This is where you can tell fans where to contact you with issues.  (Example: Having issues? Email info@trivia.com for help.)</p>
                        <textarea style={{minHeight:'100px'}} id="helpText" name="helpText" className="form-control" value={helpText} onChange={this.handleChange} />
                      </div>
                      <div className="form-group col-md-3" align="center">
                        <img src={this.state.emailBackgroundImage} width="auto" height="100px" alt=""/>
                        <br/>
                        <label htmlFor="backgroundImage">Email Header Image<br/>(700px X 400px)</label>
                        <div className="form-group">
                          <input style={{display:'none'}}  id="emailBackgroundImage" name="emailBackgroundImage" type="file" ref={input => {this.emailBackgroundImage = input; }} onChange={this.handleImageChange}/>
                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('emailBackgroundImage').click()} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpTicketEmail
