import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { base } from '../../base';
import { Modal } from 'reactstrap';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import RichTextEditor from 'html-verify-react-rte';
import { PhotoshopPicker } from 'react-color';

class SetUpTeamVariables extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            tenantVariables: {},
            showTabOne: true,
            showTabTwo: false,
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true,
            loading:true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
        this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
        });
        this.setState({
        loading: false
        })
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    handleSubmit(event) {
      event.preventDefault();
      const variables = this.state.tenantVariables || {};
        let winningBody = variables['winningBody'] || RichTextEditor.createEmptyValue();
        winningBody = winningBody.toString('html').replace(/<a /g, '<a rel="noopener noreferrer" target="_blank" ');
        if(winningBody === "<p><br></p>"){
            winningBody = "";
        }
        variables['winningBody'] = winningBody;
      /////////TO DO: MAKE SURE CORRECT NUMBER OF DIGITS

      // if(!variables.primaryColor || !variables.secondaryColor || !variables.birdImage || !variables.pipeImage || !variables.backgroundImage){
      //   swal({
      //       title: 'Missing Information',
      //       text: "Some info is missing!  Make sure to fill out all fields before updating",
      //       type: 'warning',
      //       confirmButtonText: 'Ok'
      //     })
      //   return;
      // }

      // if(this.state.tenantVariables.primaryColor.indexOf('#') === -1 || this.state.tenantVariables.secondaryColor.indexOf('#') === -1){
      //   swal({
      //       title: 'Hold on!',
      //       text: "Colors need to be in hex format!",
      //       type: 'warning',
      //       confirmButtonText: 'Ok'
      //     })
      //   return;
      // }
      var vm = this;
      base.post("tenantVariables/", {
        data: variables,
        then(err){
          vm.setState({loading:false})
          if(!err){
            swal({
                title: 'Tenant Variables Updated!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
          } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
            console.log(err)
          }
        }
      })
    }

    handleChange (evt) {
      var tenantVariablesCopy = this.state.tenantVariables;
      tenantVariablesCopy[evt.target.name] = evt.target.value
      this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    handleRichTextChange = (name, text) => {
        let tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[name] = text;
        this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    handleImageChange(event){
        const name_of_file = event.target.name;
        const target = this[name_of_file];
        const file_to_update =target.files[0];
        this.setState({loading:true})
        const vm = this;
        this.UploadImage.upload_file(file_to_update).then(res => {
            vm.setState({loading:false});
            if(res.error){
                swal({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            } else if(res.imageUrl){
                const imageUrl = res.imageUrl;
                if(imageUrl){
                    const tenantVariablesCopy = vm.state.tenantVariables;
                    tenantVariablesCopy[name_of_file] = imageUrl;
                    vm.setState({
                        tenantVariables: tenantVariablesCopy,
                    }, () => {
                        vm[name_of_file].value = ""
                    });
                } else {
                    swal({
                        title: 'Image cannot be uploaded',
                        text: 'There was some issue with the image upload, please reupload and try again',
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
            } else {
                swal({
                    title: 'Image cannot be uploaded',
                    text: 'There was some issue with the image upload, please reupload and try again',
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    switchTextShowing(element){
      if(element === "first"){
        document.getElementById('showfirst').classList.add('active');
        document.getElementById('showthird').classList.remove('active');
        this.setState({
          showTabOne: true,
          showTabTwo: false,
          showTabThree: false,
        })
      }else if(element === "second"){
        document.getElementById('showfirst').classList.remove('active');
        document.getElementById('showthird').classList.remove('active');
        this.setState({
          showTabOne: false,
          showTabTwo: true,
          showTabThree: false,
        })
      } else {
        document.getElementById('showfirst').classList.remove('active');
        document.getElementById('showthird').classList.add('active');
        this.setState({
          showTabOne: false,
          showTabTwo: false,
          showTabThree: true,
        })
      }
    }

    openColorPicker(colorToChange){
        var oldColor = this.state.tenantVariables[colorToChange] || '#fff';
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        var colorToChange = this.state.colorToUpdate;
        var tenantVariables = this.state.tenantVariables;
        tenantVariables[colorToChange] = this.state.colorToShow;
        this.setState({
            tenantVariables: tenantVariables,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        var colorToChange = this.state.colorToUpdate;
        this.setState({
            colorPickerShow: false,
        })
    }

    toggleColors(){
        if(this.state.colorsTab){
            document.getElementById('showColors').classList.remove('active');
            document.getElementById('showImages').classList.add('active');
        } else {
            document.getElementById('showColors').classList.add('active');
            document.getElementById('showImages').classList.remove('active');
        }
        this.setState({colorsTab: !this.state.colorsTab})
    }

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        let winningBody = this.state.tenantVariables.winningBody || RichTextEditor.createEmptyValue();
        if(typeof winningBody === 'string') {
            winningBody = RichTextEditor.createValueFromString(winningBody, 'html')
        }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0, marginTop:'5px'}}>Game Branding</p>
                <p className="admin-subheader-text">This is where you can edit the game branding on your activations</p>
                <div className="container-out">
                  <div className="admin-form-box" style={{marginTop:'0px', paddingTop:'5px'}}>
                    <form onSubmit={this.handleSubmit} id="create-game-form">
                      <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                          <li className="nav-item" onClick={()=> this.switchTextShowing('first')}>
                              <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showfirst">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Fan Color/Images</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.switchTextShowing('third')}>
                              <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showthird">
                                  <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Game Text</span>
                              </a>
                          </li>
                      </ul>
                        <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTabOne === true ? '' : 'none'}}>
                            <li className="nav-item" onClick={()=> this.toggleColors()}>
                                <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showColors">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Colors</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={()=> this.toggleColors()}>
                                <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showImages">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Images</span>
                                </a>
                            </li>
                        </ul>
                      <div style={{display: this.state.showTabOne ? 'block' : 'none'}}>
                        <div style={{display: this.state.colorsTab ? 'block': 'none'}}>
                          <div className="row row-cols-auto">
                              <div className="col-md-6">
                                  <h4>Button Colors</h4>
                                  <div className="form-inline">
                                      <div style={{display: "flex", alignItems: "center"}}>
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("primaryColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.primaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="primaryColor" style={{marginRight: 10}}>Button Color</label>
                                          <input id="primaryColor" name="primaryColor" className="sqwad-admin-form-text-input" type="text" value={this.state.tenantVariables.primaryColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                      </div>

                                      <div style={{height:'10px', width:'100%'}}/>
                                      <div style={{display: "flex", alignItems: "center"}}>
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("secondaryColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.secondaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="secondaryColor" style={{marginRight: 10}}>Button Text Color</label>
                                          <input id="secondaryColor" name="secondaryColor" type="text" className="sqwad-admin-form-text-input" value={this.state.tenantVariables.secondaryColor} onChange={this.handleChange} placeholder="#000"/>
                                      </div>

                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <h4>Background Colors</h4>
                                  <div className="form-inline">
                                      <div style={{display: "flex", alignItems: "center"}}>
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("sky_background_color")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.sky_background_color, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="sky_background_color" style={{marginRight: 10}}>Sky Background Color</label>
                                          <input id="sky_background_color" name="sky_background_color" type="text" className="sqwad-admin-form-text-input" value={this.state.tenantVariables.sky_background_color} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                      </div>

                                      <div style={{height:'10px', width:'100%'}}/>

                                      <div style={{display: "flex", alignItems: "center"}}>
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("land_background_color")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.land_background_color, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="land_background_color" style={{marginRight: 10}}>Land Background Color</label>
                                          <input id="land_background_color" name="land_background_color" type="text" className="sqwad-admin-form-text-input" value={this.state.tenantVariables.land_background_color} onChange={this.handleChange} placeholder="#000"/>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div style={{height:'10px', width:'100%'}}/>
                          <div className="row row-cols-auto">
                              <div className="col-md-6">
                                  <h4>Logout Colors</h4>
                                  <div className="form-inline">
                                      <div style={{display: "flex", alignItems: "center"}}>
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("logoutColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.logoutColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="logoutColor" style={{marginRight: 10}}>Button Color</label>
                                          <input id="logoutColor" name="logoutColor" type="text" className="sqwad-admin-form-text-input" value={this.state.tenantVariables.logoutColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <h4>Difficulty</h4>
                                  <div className="form-inline">
                                      <label htmlFor="pipeheight" style={{marginRight: 10}}>Vertical Gap Between Pipes (Lower is harder) </label>
                                      <input id="pipeheight" name="pipeheight" type="number" className="form-control" value={this.state.tenantVariables.pipeheight} onChange={this.handleChange} placeholder="250"/>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div style={{display: this.state.colorsTab ? 'none' : 'block'}}>
                        <div className="admin-grid-container three-columns" style={{display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap'}}>
                          <div className="form-group" style={{textAlign:'center', margin:20}}>
                            <img src={this.state.tenantVariables.sky_image} width="160" height="auto" alt=""/>
                            <br/>
                            <label htmlFor="sky_image">Background Repeating Image<br/>(276px X 109px)</label>
                            <div className="form-group">
                              <input style={{display:'none'}} id="sky_image" name="sky_image" type="file" ref={input => {this.sky_image = input; }} onChange={this.handleImageChange}/>
                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('sky_image').click()} />
                            </div>
                          </div>
                          <div className="form-group" style={{textAlign:'center', margin:20}}>
                            <img src={this.state.tenantVariables.ceiling_image} width="100" height="auto" alt=""/>
                            <br/>
                            <label htmlFor="ceiling_image">Top Barrier Image<br/>(64px X 16px)</label>
                            <div className="form-group">
                              <input style={{display:'none'}} id="ceiling_image" name="ceiling_image" type="file" ref={input => {this.ceiling_image = input; }} onChange={this.handleImageChange}/>
                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('ceiling_image').click()} />
                            </div>
                          </div>
                          <div className="form-group" style={{textAlign:'center', margin:20}}>
                            <img src={this.state.tenantVariables.land_image} width="150" height="auto" alt=""/>
                            <br/>
                            <label htmlFor="land_image">Bottom Barrier Image<br/>(336px X 112px)</label>
                            <div className="form-group">
                              <input style={{display:'none'}} id="land_image" name="land_image" type="file" ref={input => {this.land_image = input; }} onChange={this.handleImageChange}/>
                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('land_image').click()} />
                            </div>
                          </div>
                            <div className="form-group" style={{textAlign:'center', margin:20}}>
                                <img src={this.state.tenantVariables.splash_image} width="140" height="auto" alt=""/>
                                <br/>
                                <label htmlFor="splash_image">Splash Image<br/>(188px X 170px)</label>
                                <div className="form-group">
                                  <input style={{display:'none'}} id="splash_image" name="splash_image" type="file" ref={input => {this.splash_image = input; }} onChange={this.handleImageChange}/>
                                  <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('splash_image').click()} />
                                </div>
                            </div>
                            <div className="form-group" style={{textAlign:'center', margin:20}}>
                                <img src={this.state.tenantVariables.jumping_image} width="150" height="auto" alt=""/>
                                <br/>
                                <label htmlFor="jumping_image">Jumping Image<br/>(68px X 48px)</label>
                                <div className="form-group">
                                  <input style={{display:'none'}} id="jumping_image" name="jumping_image" type="file" ref={input => {this.jumping_image = input; }} onChange={this.handleImageChange}/>
                                  <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('jumping_image').click()} />
                                </div>
                            </div>
                            <div className="form-group" style={{textAlign:'center', margin:20}}>
                                <img src={this.state.tenantVariables.pipe_img} width="100" height="auto" alt=""/>
                                <br/>
                                <label htmlFor="pipe_img">Obstacle Main Image<br/>(52px X 1px)</label>
                                <div className="form-group">
                                  <input style={{display:'none'}} id="pipe_img" name="pipe_img" type="file" ref={input => {this.pipe_img = input; }} onChange={this.handleImageChange}/>
                                  <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('pipe_img').click()} />
                                </div>
                            </div>
                            <div className="form-group" style={{textAlign:'center', margin:20}}>
                                <img src={this.state.tenantVariables.backgroundImage} width="160" height="auto" alt=""/>
                                <br/>
                                <label htmlFor="backgroundImage">Login Background Image<br/>(750px X 1334px)</label>
                                <div className="form-group">
                                    <input style={{display:'none'}} id="backgroundImage" name="backgroundImage" type="file" ref={input => {this.backgroundImage = input; }} onChange={this.handleImageChange}/>
                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                                </div>
                            </div>
                            <div className="form-group" style={{textAlign:'center', margin:20}}>
                                <img src={this.state.tenantVariables.frontLogoImage} width="150" height="auto" alt=""/>
                                <br/>
                                <label htmlFor="frontLogoImage">Front Logo<br/>(700px X 700px)</label>
                                <div className="form-group">
                                    <input style={{display:'none'}} id="frontLogoImage" name="frontLogoImage" type="file" ref={input => {this.frontLogoImage = input; }} onChange={this.handleImageChange}/>
                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('frontLogoImage').click()} />
                                </div>
                            </div>
                            {/*<div className="form-group" style={{textAlign:'center', margin:20}}>*/}
                            {/*    <img src={this.state.tenantVariables.topLeftImage} width="100" height="auto" alt=""/>*/}
                            {/*    <br/>*/}
                            {/*    <label htmlFor="topLeftImage">Top Logo<br/>(200px X 200px)</label>*/}
                            {/*    <div className="form-group">*/}
                            {/*        <input style={{display:'none'}} id="topLeftImage" name="topLeftImage" type="file" ref={input => {this.topLeftImage = input; }} onChange={this.handleImageChange}/>*/}
                            {/*        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topLeftImage').click()} />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                      </div>
                        <div style={{display: this.state.showTabThree ? 'block' : 'none'}}>
                            <div className="form-group">
                                <label htmlFor="pageTitle">Page Title:</label>
                                <input id="pageTitle" name="pageTitle" type="text" className="form-control" value={this.state.tenantVariables.pageTitle} onChange={this.handleChange} placeholder="Jumper"/>
                            </div>
                            <div className="form-group" style={{marginTop: 10}}>
                                <label htmlFor="scoreDescription">High Score Description Text:</label>
                                <input id="scoreDescription" name="scoreDescription" type="text" className="form-control" value={this.state.tenantVariables.scoreDescription} onChange={this.handleChange} placeholder="Your High Score"/>
                            </div>
                            <div className="form-group" style={{marginTop: 10}}>
                                <label htmlFor="winningHeader">High Score Achieved Message Header Text:</label>
                                <input id="winningHeader" name="winningHeader" type="text" className="form-control" value={this.state.tenantVariables.winningHeader} onChange={this.handleChange} placeholder="A New Best!"/>
                            </div>
                            <div className="form-group" style={{marginTop: 10}}>
                                <label htmlFor="winningBody">High Score Achieved Message Body Text:</label>
                                <RichTextEditor id="winningBody" name="winningBody" toolbarConfig={toolbarConfig} value={winningBody} onChange={(text)=>this.handleRichTextChange("winningBody", text)}/>
                            </div>
                            <div className="form-group" style={{marginTop: 10}}>
                                <label htmlFor="otherHeader">No High Score Header Text:</label>
                                <input id="otherHeader" name="otherHeader" type="text" className="form-control" value={this.state.tenantVariables.otherHeader} onChange={this.handleChange} placeholder="Game Over!"/>
                            </div>
                            <div className="form-group" style={{marginTop: 10}}>
                                <label htmlFor="otherMessage">No High Score Body Text:</label>
                                <input id="otherMessage" name="otherMessage" type="text" className="form-control" value={this.state.tenantVariables.otherMessage} onChange={this.handleChange} placeholder="You can do better, try again"/>
                            </div>
                            <div className="form-group" style={{marginTop: 10}}>
                                <label htmlFor="playingTextHeader">Holding Header Text:</label>
                                <input id="playingTextHeader" name="playingTextHeader" type="text" className="form-control" value={this.state.tenantVariables.playingTextHeader} onChange={this.handleChange} placeholder="No Game Up"/>
                            </div>
                            <div className="form-group" style={{marginTop: 10}}>
                                <label htmlFor="playingTextBody">Holding Body Text:</label>
                                <input id="playingTextBody" name="playingTextBody" type="text" className="form-control" value={this.state.tenantVariables.playingTextBody} onChange={this.handleChange} placeholder="Come back later to play"/>
                            </div>
                            <div className="form-group" style={{marginTop: 10}}>
                                <label htmlFor="enterInfoText">Enter Information Text:</label>
                                <input id="enterInfoText" name="enterInfoText" type="text" className="form-control" value={this.state.tenantVariables.enterInfoText} onChange={this.handleChange} placeholder="Enter your email to play"/>
                            </div>
                        </div>
                    </form>
                  </div>
                </div>
            </div>
              <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                  <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
              </Modal>
         </div>
        );
    }
}

export default SetUpTeamVariables;
