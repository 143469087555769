import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { base } from '../../base';
import swal from 'sweetalert2';
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone'

class SetUpScratcherTickets extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
          ticketList: [],
          editingTicket: false,
          rewardToEdit: null,
          imageUrl: null,
          modal: false,
          emailImage: null,
          rewardName: "",
          rewardsList: [],
          rewardLink: "",
          rewardDescription: "",
          editTicketId: "",
          editTicketEmailImage: '',
          editTicketPictureUnderScratcher: '',
          revealImagePreview: {},
          emailImagePreview: {},
          loading:true,
          files: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        swal({
            title: 'Image cannot be uploaded',
            text: 'Make sure the image is less than 2mbs and it is an accepted file type',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      this.setState({loading:true})

      this.UploadImage.upload_file(files[0]).then(res => {
        this.setState({loading:false})
        if(res.data.imageUrl){
          var nameToUpdate = myArgument + "Preview"
          var fileToUpdate = files[0]
          this.setState({
            emailImage: res.data.imageUrl,
            [nameToUpdate]: ({
              fileToUpdate,
              preview: URL.createObjectURL(fileToUpdate)
            })
          });
        } else {
          swal({
              title: 'Image cannot be uploaded',
              text: 'There was some issue with the image upload, please reupload and try again',
              type: 'warning',
              confirmButtonText: 'Ok'
            })
        }
      })
    }

    componentDidMount(){
        this.rewardsListRef = base.syncState(`rewardsList`, {
            context: this,
            state: 'rewardsList',
            asArray: true
        });
        this.setState({loading:false})
    }

    componentWillUnmount() {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(this.state.revealImagePreview.preview);
      URL.revokeObjectURL(this.state.emailImagePreview.preview);
      base.removeBinding(this.rewardsListRef);
    }

    handleChange(event){
      this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
      event.preventDefault();
      if(this.state.loading){
        return
      }

      var rewardName = this.state.rewardName || "";
      if(rewardName.length > 50){
        swal({
            title: 'Oh uh!',
            text: 'Reward name cannot be longer than 50 characters!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      var rewardLink = this.state.rewardLink || "";
      var rewardDescription = this.state.rewardDescription || "";
      var emailImage = this.state.emailImage;
      var vm = this;
      if(!rewardName || !rewardDescription || !emailImage){
        swal({
            title: 'Not all fields filled out!',
            text: 'Please enter them and try again',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      var rewardToSave = {}
      rewardToSave['rewardName'] = rewardName;
      rewardToSave['emailImage'] = emailImage;
      rewardToSave['rewardLink'] = rewardLink;
      rewardToSave['description'] = rewardDescription;
      this.setState({loading:true})
      setTimeout(function () {
      if(!vm.state.editingTicket){
        base.push('rewardsList', {
          data: rewardToSave,
          then(err){
            if(!err){
              vm.setState({
                rewardDescription: "",
                fileEmailImage: null,
                emailImage: null,
                rewardName: "",
                rewardLink: "",
                loading:false,
                modal: false
              })
              swal({
                  title: 'Winning Ticket Created!',
                  type: 'success',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: true,
                  timer: 10000
                })
            } else {
              vm.setState({loading:false})
              swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              console.log(err)
            }
          }
        })
      } else {
        var selectedRewardId = vm.state.rewardToEdit.key;
        base.post(`rewardsList/${selectedRewardId}`, {
          data: rewardToSave,
          then(err){
            if(!err){
              vm.setState({
                rewardDescription: "",
                fileEmailImage: null,
                rewardName: "",
                emailImage: null,
                rewardLink: "",
                rewardToEdit: null,
                revealImagePreview: {},
                emailImagePreview: {},
                editingTicket: false,
                modal: false,
                loading:false
              })
              vm.setState({loading:false})
              swal({
                  title: 'Ticket Edited!',
                  type: 'success',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: true,
                  timer: 10000
                })
            } else {
              vm.setState({loading:false})
              swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              console.log(err)
            }
          }
        })
      }
    }, 2000);
    }

    editTicket(event){
      event.preventDefault();
      var array = this.state.rewardsList;
      var reward = array[event.target.value];
      console.log(reward)
      this.setState({
        modal: true,
        rewardName: reward.rewardName,
        rewardLink: reward.rewardLink,
        rewardDescription: reward.description,
        editTicketEmailImage:reward.emailImage,
        rewardToEdit: reward,
        editingTicket: true,
      });
    }

    deleteTicket(e){
      e.preventDefault();
      var array = this.state.rewardsList;
      var index = array[e.target.value];
      swal({
            title: 'Delete Ticket?',
            text: 'Are you sure you want to do this?  You will no longer be able to use this ticket in any new games',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result)=>{
          if (result) {
            base.remove('rewardsList/' + index.key, function(err) {
              if(err){
                swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            })
          }
        })
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        editingTicket: false,
      });
    }

    render() {
      var winningTicketList = this.state.rewardsList;
      var emailImagePreview = this.state.emailImagePreview;
      var revealImagePreview = this.state.revealImagePreview;

        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}></div>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Prizes</p>
                    <p className="admin-subheader-text">These are prizes fans will receive when they win Trivia</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Prize</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                      {
                        winningTicketList.map(function(item,i){
                          return <div key={i} className="card">
                              <div className="card-body" align="center">
                                <p style={{marginTop:5}}>{item.rewardName}</p>
                                <p>
                                  <img width="80%" height="auto" src={item.emailImage} alt=""/>
                                </p>
                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editTicket.bind(this)} value={i}><span className="fa fa-ellipsis-v"></span> Edit</button>
                                <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o"></span> Delete</button>
                              </div>
                            </div>
                        }, this)
                      }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}></div>
              <ModalHeader toggle={this.toggle}>Add Prize</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                        <div className="form-group" >
                          <label htmlFor="rewardName">Prize Name</label>
                          <input id="rewardName" name="rewardName" type="text" className="form-control" value={this.state.rewardName} onChange={this.handleChange} placeholder="My Sweet Prize"/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="rewardLink">Prize Link (Optional)</label>
                          <input id="rewardLink" name="rewardLink" type="url" className="form-control" value={this.state.rewardLink} onChange={this.handleChange} placeholder="http://my_sweet_prize_link.com"/>
                        </div>
                        <div className="form-group">
                          <label htmlFor="rewardDescription">Prize Description: </label>
                          <textarea id="rewardDescription" name="rewardDescription" className="form-control" value={this.state.rewardDescription} onChange={this.handleChange} placeholder="My Sweet Prize Description"/>
                        </div>
                        <div className="form-group" align="center">
                          <label htmlFor="rewardEmailImage" style={{width:'100%'}}>Prize Email Image</label>
                          <img src={this.state.editTicketEmailImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                          <Dropzone
                            className="dropzone dz-clickable"
                            accept="image/*"
                            onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'emailImage')}}
                            multiple={false}
                            maxSize={2200000}>
                            <div className="dz-message needsclick">
                              <span className="fa fa-cloud-upload text-muted" style={{display: emailImagePreview.preview ? 'none' : ''}}></span>
                              <h3 style={{display: emailImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                              <img
                                style={{display: emailImagePreview.preview ? '' : 'none'}}
                                src={emailImagePreview.preview}
                                width="100px"
                                height="auto"
                                alt="Drop your image here"
                              />
                            </div>
                          </Dropzone>
                        </div>
                        <div className="form-group text-center">
                          <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
         </div>
        );
    }
}

export default SetUpScratcherTickets
